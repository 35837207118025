import React, { useState, useMemo } from 'react';
import Layout from '../organisms/layout';
import Seo from '../organisms/seo';
import { graphql } from 'gatsby';
import withPreview from '../utils/with-preview';
import Breadcrumb from '../organisms/breadcrumb/Breadcrumb';
import MenuStickySinglePage from '../molecules/menu-sticky-single-page/MenuStickySinglePage';
import { getSEOValue } from '../utils/functions';
import useWithCategoryAttributes from '../utils/hooks/useWithCategoryAttributes';
import {
  MapAnchoredModules,
  MapNonAnchoredModules,
} from '../molecules/modules-helpers/ModulesHelpers';
import { generateMenuAnchors } from '../utils/anchorHelpers';

const PageTemplate = ({
  data: { liferayModuleDetailProduct, allLiferayModuleHighlightTopPage },
  location,
}) => {
  const [activeModule, setActiveModule] = useState(null);
  const categories = useWithCategoryAttributes(
    liferayModuleDetailProduct?.liferayFields?.taxonomyCategoryNames
  );
  const pageSettings = {
    pageType: 'business', // detail product pages are always business
    showChangeLanguageButton:
      liferayModuleDetailProduct?.showChangeLanguageButton?.value,
    linkLangMap: liferayModuleDetailProduct?.linkLangMap,
    langCode: liferayModuleDetailProduct.liferayFields?.langCode,
    lateralStickerAnchor: liferayModuleDetailProduct?.lateralStickerAnchor,
    showNavigationMenu: liferayModuleDetailProduct?.showNavigationMenu?.value,
    showBreadcrumb: liferayModuleDetailProduct?.showBreadcrumb?.value,
    showHomePage:
      liferayModuleDetailProduct?.showBreadcrumb?.content?.showHomePage?.value,
    scripts: liferayModuleDetailProduct?.scripts,
  };

  const pageSEOSettings = getSEOValue(liferayModuleDetailProduct.detailPageSeo);

  const buttonCtaLink =
    liferayModuleDetailProduct?.showNavigationMenu?.content?.ctaLink?.value;
  const buttonCtaLabel =
    liferayModuleDetailProduct?.showNavigationMenu?.content?.ctaLabel?.value;

  const showButtonBack =
    liferayModuleDetailProduct?.showNavigationMenu?.content?.showBackButton
      ?.value;
  const labelButtonBack =
    liferayModuleDetailProduct?.showNavigationMenu?.content?.showBackButton
      ?.content?.labelBackButton?.value;

  const anchoredModules =
    liferayModuleDetailProduct?.moduleList?.content?.anchoredModules?.filter(
      (module) => !!module.value
    ) || [];
  const notAnchoredModules =
    liferayModuleDetailProduct?.moduleList?.content?.notAnchoredModules?.filter(
      (module) => !!module.value
    ) || [];

  const anchorBefore =
    liferayModuleDetailProduct?.moduleList?.content?.anchorBefore?.value[0];
  const anchorTopPageText =
    liferayModuleDetailProduct?.moduleList?.content?.anchorTopPageText?.value;

  const menuAnchors =
    pageSettings.showNavigationMenu &&
    generateMenuAnchors(
      anchoredModules,
      notAnchoredModules,
      anchorBefore,
      anchorTopPageText
    );

  const breadcrumbs = useMemo(() => {
    const homePage = { label: 'Home', link: '/' };
    const breadcrumbsTemp = liferayModuleDetailProduct.breadcrumbs
      .filter((page) => page.link !== '')
      .map((page) => ({
        label: page.pageTitle?.value || page.detailPageTitle?.value,
        link: page.link,
      }))
      .concat({
        label: liferayModuleDetailProduct.detailPageTitle?.value,
      });
    if (pageSettings.showHomePage) {
      breadcrumbsTemp.unshift(homePage);
    }
    return breadcrumbsTemp;
  }, [liferayModuleDetailProduct, pageSettings.showHomePage]);

  const labelPenultimateElement = breadcrumbs.slice(-2, -1)[0]?.label;
  const linkPenultimateElement = breadcrumbs.slice(-2, -1)[0]?.link;

  const bannerTop = liferayModuleDetailProduct?.bannerTop
    ? liferayModuleDetailProduct?.bannerTop?.value
    : allLiferayModuleHighlightTopPage?.nodes?.find(
        (highlight) => highlight?.showOnAllPagesBannerTop?.value === true
      )
    ? allLiferayModuleHighlightTopPage?.nodes?.find(
        (highlight) => highlight?.showOnAllPagesBannerTop?.value === true
      )
    : null;

  return (
    <Layout pageSettings={pageSettings} bannerTop={bannerTop}>
      <Seo
        title={
          pageSEOSettings.ogTitle ||
          liferayModuleDetailProduct.detailPageTitle.value
        }
        description={pageSEOSettings.ogDescription}
        image={pageSEOSettings.ogImage}
        link={pageSEOSettings.ogUrl || liferayModuleDetailProduct.link}
        type={pageSEOSettings.ogType}
        scripts={pageSettings.scripts}
        jsonLd={pageSEOSettings.structuredDataJsonLd}
      />
      <div className='container' data-search-type='product' {...categories}>
        {!!notAnchoredModules?.length &&
          pageSettings.showBreadcrumb &&
          breadcrumbs.length && <Breadcrumb items={breadcrumbs}></Breadcrumb>}
        <MapNonAnchoredModules
          nonAnchoredModules={notAnchoredModules}
          setActiveModule={setActiveModule}
        />
        {pageSettings.showNavigationMenu && (
          <MenuStickySinglePage
            anchors={menuAnchors}
            activeAnchor={activeModule}
            buttonCtaLink={buttonCtaLink}
            buttonCtaText={buttonCtaLabel}
            showButtonBack={showButtonBack}
            labelButtonBack={labelButtonBack + ' ' + labelPenultimateElement}
            linkButtonBack={linkPenultimateElement}
            hasModulesWithoutAnchorAbove={!!notAnchoredModules.length}
          />
        )}
        {!notAnchoredModules?.length &&
          pageSettings.showBreadcrumb &&
          breadcrumbs.length && <Breadcrumb items={breadcrumbs}></Breadcrumb>}
        <MapAnchoredModules
          anchoredModules={anchoredModules}
          setActiveModule={setActiveModule}
          showNavigationMenu={pageSettings.showNavigationMenu}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    liferayModuleDetailProduct(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
        langCode
        taxonomyCategoryNames
      }
      detailPageTitle {
        value
      }
      breadcrumbs {
        ... on LiferayPageGeneric {
          pageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailProduct {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailBlog {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailIniziative {
          detailPageTitle {
            value
          }
          link
        }
      }
      showChangeLanguageButton {
        value
      }
      showBreadcrumb {
        value
        content {
          showHomePage {
            value
          }
        }
      }
      scripts {
        value
      }
      detailPageSeo {
        value {
          ... on LiferaySubmoduleSeo {
            ogTitle {
              value
            }
            ogType {
              value
            }
            ogDescription {
              value
            }
            ogImageData {
              node {
                publicURL
              }
            }
            ogUrl {
              value
            }
            structuredDataJsonLd {
              value
            }
          }
        }
      }
      lateralStickerAnchor
      link
      linkLangMap
      liferayFields {
        langCode
      }
      showNavigationMenu {
        value
        content {
          ctaLabel {
            value
          }
          ctaLink {
            value
          }
          showBackButton {
            value
            content {
              labelBackButton {
                value
              }
            }
          }
        }
      }
      bannerTop {
        value {
          ... on LiferayModuleHighlightTopPage {
            ...QueryHighlightTopPage
          }
        }
      }
      moduleList {
        content {
          anchorTopPageText {
            value
          }
          anchorBefore {
            value
          }
          notAnchoredModules {
            value {
              __typename
              ...QueryAccordion
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryBannerImmagineCircolare
              ...QueryCardsIconeTestoFooter
              ...QueryFasciaCardEtichettaCircolare
              ...QueryFasciaCardImg
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFastQuote
              ...QueryHero
              ...QueryLateralSticker
              ...QueryPhotogallery
              ...QueryScaricaApp
              ...QueryShortlink
              ...QueryTestoIntroduttivo
              ...QueryVideo
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryAssetList
              ...QueryPhoneBoxes
              ...QueryFasciaBlog
              ...QueryLandingForm
            }
          }
          anchoredModules {
            value {
              __typename
              ...QueryAccordion
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryBannerImmagineCircolare
              ...QueryCardsIconeTestoFooter
              ...QueryFasciaCardEtichettaCircolare
              ...QueryFasciaCardImg
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFastQuote
              ...QueryHero
              ...QueryLateralSticker
              ...QueryPhotogallery
              ...QueryScaricaApp
              ...QueryShortlink
              ...QueryTestoIntroduttivo
              ...QueryVideo
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryAssetList
              ...QueryPhoneBoxes
              ...QueryFasciaBlog
              ...QueryLandingForm
            }
          }
        }
      }
    }
    allLiferayModuleHighlightTopPage {
      nodes {
        bannerTop {
          content {
            bannerTopImage {
              node {
                gatsbyImageData(width: 100)
              }
            }
            colorBannerTop {
              value
            }
            ctaLabelBannerTop {
              value
              content {
                ctaColorBannerTop {
                  value
                }
                ctaLinkBannerTop {
                  value
                }
                ctaTypeBannerTop {
                  value
                }
                iconSvgIdCtaBannerTop {
                  value
                  content {
                    iconThicknessCtaBannerTop {
                      value
                    }
                  }
                }
              }
            }
            iconSvgIdBannerTop {
              value
              content {
                iconThicknessBannerTop {
                  value
                }
              }
            }
            tagTitleBannerTop {
              value
            }
            textBannerTop {
              value
            }
            titleBannerTop {
              value
            }
          }
        }
        showOnAllPagesBannerTop {
          value
        }
        htmlTagWrapper {
          value
        }
        liferayFields {
          articleId
          siteId
        }
      }
    }
  }
`;

export default withPreview(PageTemplate, {
  subField: 'liferayModuleDetailProduct',
  fixed: true,
});
